<template>
  <base-section
    id="eligibility"
    class="mb-0 pb-0"
  >
    <base-section-heading
      icon="mdi-account-school-outline"
      title="Eligibility & Access"
    />

    <v-container>
      <v-img
        :height="$vuetify.breakpoint.mdAndUp ? 400 : 225"
        contain
        :src="require('@/assets/certificate.jpg')"
      />
      <br>
      <br>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="3"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionEligibility',

    data: () => ({
      cards: [
        {
          title: 'Students',
          subtitle: '(College / University Level)',
          text: 'Undergraduate or graduate students pursuing degrees in engineering, computer science, or related fields.',
          callout: '01',
        },
        {
          title: 'Professionals Seeking Career Development',
          subtitle: '',
          text: 'Engineers looking to specialise in robotics or enhance their skill set.',
          callout: '02',
        },
        {
          title: 'Industry Professionals',
          subtitle: '',
          text: 'Employees from various industries (manufacturing, healthcare, logistics, etc.) seeking to implement robotics solutions in their workplace.',
          callout: '03',
        },
        {
          title: 'Educators & Instructors',
          subtitle: '',
          text: 'Teachers or educators seeking to integrate robotics into their curriculum.',
          callout: '04',
        },
      ],
    }),
  }
</script>
